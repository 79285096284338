import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { highlightSubject, highlightSearchTerm } from '../utils/subjectUtils';
import ShareButton from './ShareButton';
import './SubjectsTable.css';
import config from '../config';

const SubjectsTable = ({
  subjects, page, rowsPerPage, totalCount = 0, onPageChange, onRowsPerPageChange, topics = undefined, searchTerm = '', searchType = ''
}) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768; // Check if the device is mobile

  const location = useLocation();  
  const params = new URLSearchParams(location.search);
  const topicParam = params.get('id');  
  const current_topic = topics?.filter(e => e[`${config.dataNames.topics}_index`] === Number(topicParam));

  const subjectTopicsGroup = (topics, subject) => {
      if (!topics) {
        return '';
      }
      let str = subject[config.dataNames.topics].map(
        id => topics.findLast(it => it[`${config.dataNames.topics}_index`] === id)
      ).map(e => e && e.topic).join(',');

      if (current_topic?.length > 0 ) {
        const currentTopicStr = current_topic[0].topic;
        const Tag = highlightSubject(str, currentTopicStr);
        return (
          <>
            <span className="source-note">
              {Tag}
            </span>
          </>
        );
      }
      return str;
  }

  return (
    <TableContainer component={Paper} className="subjects-table-container">
      {totalCount > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className="table-header" style={{ width: isMobile ? '35%' : '25%' }}>
              <font className="subject-link">{t('subject')}</font>
            </TableCell>
            <TableCell className="table-header" style={{ width: isMobile ? '35%' : '35%' }}>
              {t('context')}
              <span className="source-note"> [from the Youtube videos]</span>
            </TableCell>
            <TableCell className="table-header" style={{ width: '30%' }}>{t('contextExplanation')}</TableCell>
            {!isMobile && <TableCell className="table-header" style={{ width: '8%' }}>{t('videoDate')}</TableCell>}
            {!isMobile && <TableCell className="table-header" style={{ width: '2%' }}>{t('share')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {subjects.map((subject) => (
            <TableRow key={subject.id}>
              <TableCell className="table-cell">
                <span><i>[{subject[`${config.dataNames.subject}Name`]}]</i></span><br />
                <a href={subject.youtubeLink} target="_blank" rel="noopener noreferrer" className="subject-link">
                  {searchTerm && (searchType === 'prefix' || searchType === 'keywords') 
                    ? highlightSearchTerm(subject[config.dataNames.subject], searchTerm, searchType)
                    : subject[config.dataNames.subject]
                  }
                </a> {isMobile && (<ShareButton id={subject.id} subject={subject[config.dataNames.subject].substring(0, 25)} />)}
                {isMobile && (
                  <>
                    <br />
                    <span>{dayjs(subject.videoDate).format('YYYY-MM-DD')}</span>
                  </>
                )}
              </TableCell>
              <TableCell className="table-cell">
                {highlightSubject(subject.context, subject[config.dataNames.subject])}
                {topics ? <br /> : ''}
                {subjectTopicsGroup(topics, subject)}
              </TableCell>
              <TableCell className="table-cell">{subject[`${config.dataNames.subject}Summary`]}</TableCell>
              {!isMobile && <TableCell className="table-cell">{dayjs(subject.videoDate).format('YYYY-MM-DD')}</TableCell>}
              {!isMobile && (
                <TableCell className="table-cell">
                  <ShareButton id={subject.id} subject={subject[config.dataNames.subject].substring(0, 25)} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubjectsTable;