const config = {
    apiUrl: 'https://russell-nelson-backend-dot-prophet17.uc.r.appspot.com/',
    defaultLimit: 10,
    defaultOffset: 0,
    routeNames: {
        subjects: 'subjects',
        topics: 'topics'
    },
    dataNames: {
        subjects: 'subjects',
        subject: 'subject',
        topics: 'emotions'        
    },
    languages: [
        { code: 'en', name: 'English' },
        // { code: 'zh', name: '中文' }
    ],
    topicsSentiments: ['positive'],
    aiOutputs: [
        "subjectName",
        "subjectSummary"        
    ],
    videoWatchSeconds: 180
};
  
export default config;