import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Typography, Box, Alert, CircularProgress, Switch, FormControlLabel, Link, Grid, IconButton } from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import DomainIcon from '@mui/icons-material/Domain';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import SubjectsTable from '../components/SubjectsTable';
import LanguageSwitcher from '../components/LanguageSwitcher';
import TVModePanel from '../components/TVModePanel';
import TopicsGrid from '../components/TopicsGrid';
import './WelcomePage.css';
import Footer from '../components/Footer';

import { 
  fetchLatestSubjects, 
  fetchSubjectsCount, 
  fetchTopics, 
  searchSubjectById,
  searchSubjects,
  searchSubjectsByKeywords,
} from '../services/apiService';

const WelcomePage = () => {
  const { t, i18n } = useTranslation();
  const [latestSubjects, setLatestSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subjectsCount, setSubjectsCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tvMode, setTvMode] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [searchType, setSearchType] = useState('prefix');
  const [searchWords, setSearchWords] = useState('');  

  const location = useLocation();
  const initialSearchTermRef = useRef(null);
  const initialSearchIdRef = useRef(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [latestSubjectsData, subjectsCountData, topicsData] = await Promise.all([
        fetchLatestSubjects(page, rowsPerPage),
        fetchSubjectsCount(),
        fetchTopics()
      ]);

      setLatestSubjects(latestSubjectsData);
      setSubjectsCount(subjectsCountData);
      setTopics(topicsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();

    const params = new URLSearchParams(location.search);
    const idParam = params.get('id');
    const subjectParam = params.get('subject');
    const langParam = params.get('lang');
    if (idParam) {
      initialSearchIdRef.current = idParam;
    }
    if (subjectParam) {
      initialSearchTermRef.current = subjectParam;      
    }
    if (langParam) {
      i18n.changeLanguage(langParam);
    }
  }, [fetchData, location.search, i18n]);

  useEffect(() => {
    if (initialSearchTermRef.current || initialSearchIdRef.current) {
      handleSearch(initialSearchTermRef.current, initialSearchIdRef.current);
    }
  }, []);

  const handleSearch = async (searchTerm, searchId, type) => {
    if (!searchTerm?.trim() && !searchId?.trim()) {
      setSearchResults(null);
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    try {
      let results;
      if (searchId) {
        results = await searchSubjectById(searchId);
        if (results) {
          results = [results];
        }
      } else if (type === 'prefix') {
        results = await searchSubjects(searchTerm);
      } else {
        results = await searchSubjectsByKeywords(searchTerm);
      }
      setSearchWords(searchTerm);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching subjects:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchTypeChange = (newType) => {
    setSearchType(newType);
  };

  const handleClearSearch = () => {
    setSearchResults(null);
    setIsSearching(false);
    initialSearchTermRef.current = null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setVideoIndex(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleTvMode = () => {
    setTvMode(!tvMode);
  };

  const hasNextPage = (page + 1) * rowsPerPage < subjectsCount;

  const handleNextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
    setVideoIndex(0);
  }, []);

  const subjectsToDisplay = searchResults !== null ? searchResults : latestSubjects;

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <IconButton 
          component="a" 
          href="http://profoundwords.us" 
          aria-label="home"
        >
          <DomainIcon />
        </IconButton>
        <Box className="language-switcher">
          <LanguageSwitcher />
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" className="welcome-title">
          <Link underline='none' href='/'>{t('welcome')}</Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
        <SearchBar 
          onSearch={(term) => handleSearch(term, null, searchType)} 
          onClear={handleClearSearch} 
          initValue={initialSearchTermRef.current || ''} 
          searchType={searchType}
          onSearchTypeChange={handleSearchTypeChange}
        />
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LiveTvIcon sx={{ paddingRight: '8px', color: 'red'}}/>
        <FormControlLabel
          control={<Switch checked={tvMode} onChange={handleToggleTvMode} />}
          label={t('tv')}
          sx={{
            '& .MuiFormControlLabel-label': {
              color: 'blue',
            },
          }}
        />
      </Box>
      {tvMode && (
        <TVModePanel 
          key={page}
          subjects={subjectsToDisplay} 
          initIndex={videoIndex}
          hasNextPage={hasNextPage}
          onNextPage={handleNextPage}
        />        
      )}      
      
      {isLoading ? (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <TopicsGrid topics={topics} />   
          {isSearching ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : searchResults !== null ? (
            <Box>
              <Typography className="section-header">{t('searchResults')}</Typography>
              {searchResults.length > 0 ? (
                <SubjectsTable 
                  subjects={searchResults} 
                  searchTerm={searchWords}
                  searchType={searchType}
                />
              ) : (
                <Alert severity="info">{t('noResults')}</Alert>
              )}
            </Box>
          ) : (
            <Box>
              <Typography className="section-header">{t('latestSubjects')}</Typography>
              <SubjectsTable
                subjects={latestSubjects}
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={subjectsCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </>
      )}
      <Footer />
    </Container>
  );
};

export default WelcomePage;