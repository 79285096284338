import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Typography, Box, Alert, CircularProgress, Switch, FormControlLabel, Link, Grid } from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import TopicsGrid from '../components/TopicsGrid';
import SubjectsTable from '../components/SubjectsTable';
import LanguageSwitcher from '../components/LanguageSwitcher';
import TVModePanel from '../components/TVModePanel';
import ShareButton from '../components/ShareButton'; 
import './WelcomePage.css';
import Footer from '../components/Footer';
import { 
  fetchSubjectsByTopic, 
  fetchTopics, 
  fetchSubjectsCountByTopic,
  searchSubjectsByTopic,
  searchSubjectsByTopicByKeywords
} from '../services/apiService';
import config from '../config';

const TopicsPage = () => {
  const { t, i18n } = useTranslation();
  const [latestSubjects, setLatestSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subjectsCount, setSubjectsCount] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tvMode, setTvMode] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [searchType, setSearchType] = useState('prefix');
  const [searchWords, setSearchWords] = useState('');

  const location = useLocation();
  const initialSearchTermRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const topicParam = params.get('id');
  const subjectParam = params.get('subject');
  const langParam = params.get('lang');  

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [latestSubjectsData, topicsData, subjectsCountData] = await Promise.all([
        fetchSubjectsByTopic(page, rowsPerPage, topicParam),
        fetchTopics(),
        fetchSubjectsCountByTopic(topicParam)
      ]);

      setLatestSubjects(latestSubjectsData);
      setTopics(topicsData);
      setSubjectsCount(subjectsCountData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage, topicParam]);

  useEffect(() => {
    fetchData();

    if (subjectParam) {
      initialSearchTermRef.current = subjectParam;
    }
    if (langParam) {
      i18n.changeLanguage(langParam);
    }
  }, [fetchData, langParam, i18n]);

  useEffect(() => {
    if (initialSearchTermRef.current) {
      handleSearch(initialSearchTermRef.current, searchType);
    }
  }, []);

  const handleSearch = async (searchTerm, type) => {
    if (!searchTerm.trim()) {
      setSearchResults(null);
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    try {
      let results;
      if (type === 'prefix') {
        results = await searchSubjectsByTopic(searchTerm, topicParam);
      } else {
        results = await searchSubjectsByTopicByKeywords(searchTerm, topicParam);
      }
      setSearchWords(searchTerm);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching subjects:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleClearSearch = () => {
    setSearchResults(null);
    setIsSearching(false);
    initialSearchTermRef.current = null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setVideoIndex(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleTvMode = () => {
    setTvMode(!tvMode);
  };

  const handleSearchTypeChange = (newType) => {
    setSearchType(newType);
  };

  const hasNextPage = (page + 1) * rowsPerPage < subjectsCount;

  const handleNextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
    setVideoIndex(0);
  }, []);  

  const subjectsToDisplay = searchResults !== null ? searchResults : latestSubjects;

  const current_topic = topics.filter(e => e[`${config.dataNames.topics}_index`] === Number(topicParam));

  return (
    <Container>
      <Box className="language-switcher">
        <LanguageSwitcher />
      </Box>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" className="welcome-title">
          <Link underline='none' href='/'>{t('welcome')}</Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <SearchBar 
          onSearch={(term) => handleSearch(term, searchType)} 
          onClear={handleClearSearch} 
          initValue={initialSearchTermRef.current || ''} 
          searchType={searchType}
          onSearchTypeChange={handleSearchTypeChange}
        />
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LiveTvIcon sx={{ paddingRight: '8px', color: 'red'}}/>
        <FormControlLabel
          control={<Switch checked={tvMode} onChange={handleToggleTvMode} />}
          label={t('tv')}
          sx={{
            '& .MuiFormControlLabel-label': {
              color: 'blue',
            },
          }}
        />
      </Box>
      {tvMode && (
        <TVModePanel 
          key={page}
          subjects={subjectsToDisplay} 
          initIndex={videoIndex}
          hasNextPage={hasNextPage}
          onNextPage={handleNextPage}
        />        
      )}      
      {isLoading ? (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <TopicsGrid topics={topics} />        
          {isSearching ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : searchResults !== null ? (
            <Box>
              <Typography className="section-header">{t('searchResults')}</Typography>
              {searchResults.length > 0 ? (
                <SubjectsTable 
                  subjects={searchResults} 
                  searchTerm={searchWords}
                  searchType={searchType}
                />
              ) : (
                <Alert severity="info">{t('noResults')}</Alert>
              )}
            </Box>
          ) : (
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className="section-header">
                  <b><u>{
                    current_topic.length ?
                      current_topic[0].topic.charAt(0).toUpperCase() + current_topic[0].topic.slice(1) :
                      ''
                  }</u></b> {t('situation')}
                  {current_topic.length > 0 && (
                    <ShareButton 
                      subject={current_topic[0].topic} 
                      customUrl={window.location.href}
                    />
                  )}                  
                </Typography>
              </Box>
              <SubjectsTable
                subjects={latestSubjects}
                page={page}
                topics={topics}
                rowsPerPage={rowsPerPage}
                totalCount={subjectsCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </>
      )}
      <Footer />
    </Container>
  );
};

export default TopicsPage;