import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, TextField, LinearProgress } from '@mui/material';
import { highlightSubject } from '../utils/subjectUtils';
import './TVModePanel.css';
import { useTranslation } from 'react-i18next';
import config from '../config';

const TVModePanel = ({ subjects, initIndex = 0, hasNextPage, onNextPage }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(initIndex);
  const [secondsToWatch, setSecondsToWatch] = useState(config.videoWatchSeconds);
  const [remainingSeconds, setRemainingSeconds] = useState(config.videoWatchSeconds);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const playerRef = useRef(null);
  const youtubePlayerRef = useRef(null);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    if (!window.YT) {
      loadYouTubeAPI();
    } else {
      initializePlayer();
    }

    window.onYouTubeIframeAPIReady = initializePlayer;

    return () => {
      if (youtubePlayerRef.current) {
        youtubePlayerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    setCurrentIndex(initIndex);
    setRemainingSeconds(secondsToWatch);
    if (isPlayerReady && youtubePlayerRef.current && initIndex < subjects.length) {
      const { videoId, startTime } = extractVideoInfo(subjects[initIndex].youtubeLink);
      youtubePlayerRef.current?.loadVideoById({
        videoId: videoId,
        startSeconds: startTime,
      });
    }
  }, [initIndex, subjects, isPlayerReady, secondsToWatch]);

  useEffect(() => {
    let timer;
    if (isPlaying && currentIndex < subjects.length) {
      timer = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds <= 1) {
            nextVideo();
            return secondsToWatch;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isPlaying, secondsToWatch, currentIndex, subjects.length]);

  useEffect(() => {
    if (isPlayerReady && youtubePlayerRef.current && currentIndex < subjects.length) {
      const { videoId, startTime } = extractVideoInfo(subjects[currentIndex].youtubeLink);
      youtubePlayerRef.current.loadVideoById({
        videoId: videoId,
        startSeconds: startTime,
      });
    }
  }, [currentIndex, subjects, isPlayerReady]);

  const initializePlayer = () => {
    if (subjects.length > 0) {
      const { videoId, startTime } = extractVideoInfo(subjects[initIndex].youtubeLink);
      youtubePlayerRef.current = new window.YT.Player(playerRef.current, {
        height: '360',
        width: '640',
        videoId: videoId,
        playerVars: {
          start: startTime,
        },
        events: {
          onReady: (event) => {
            setIsPlayerReady(true);
            event.target.playVideo();
          },
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.ENDED) {
              nextVideo();
            }
          },
        },
      });
    }
  };

  const extractVideoInfo = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    const videoId = (match && match[2].length === 11) ? match[2] : null;

    const timeRegExp = /[?&]t=(\d+)/;
    const timeMatch = url.match(timeRegExp);
    const startTime = timeMatch ? parseInt(timeMatch[1], 10) : 0;

    return { videoId, startTime };
  };

  const nextVideo = () => {
    if (currentIndex < subjects.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setRemainingSeconds(secondsToWatch);
    } else {
      setIsPlaying(false);
      setCurrentIndex(0); // Reset to the first video when reaching the end
    }
  };

  const handleSecondsToWatchChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setSecondsToWatch(value);
      setRemainingSeconds(value);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlayerReady && youtubePlayerRef.current) {
      if (isPlaying) {
        youtubePlayerRef.current.pauseVideo();
      } else {
        youtubePlayerRef.current.playVideo();
      }
    }
  };

  const handleNextPage = () => {
    onNextPage();
    setCurrentIndex(0);
    setRemainingSeconds(secondsToWatch);
  };  

  if (currentIndex >= subjects.length) {
    return <Typography variant="h6">{t('tvModeFinished')}</Typography>;
  }

  const currentSubject = subjects[currentIndex];

  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6">
        <span className="now-playing-label">{t('nowPlaying')}: </span>{currentSubject[`${config.dataNames.subject}Name`]}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
        {highlightSubject(currentSubject.context, currentSubject[config.dataNames.subject])}
        <span className="source-note"> [from the Youtube video]</span>
      </Typography>
      <Box ref={playerRef} sx={{ width: '640px', height: '360px' }} />
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', width: '100%', maxWidth: '640px' }}>
        <TextField
          label={t('secondsToWatch')}
          type="number"
          value={secondsToWatch}
          onChange={handleSecondsToWatchChange}
          sx={{ mr: 2, width: '150px' }}
        />
        <LinearProgress
          variant="determinate"
          value={(remainingSeconds / secondsToWatch) * 100}
          sx={{ flexGrow: 1 }}
        />
        <Typography variant="body2" sx={{ ml: 2, minWidth: '40px' }}>
          {remainingSeconds}s
        </Typography>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '640px' }}>
        <Button
          variant="contained"
          onClick={() => {
            setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
            setRemainingSeconds(secondsToWatch);
          }}
          disabled={currentIndex === 0}
        >
          {t('previousSubject')} ({currentIndex})
        </Button>
        <Button
          variant="contained"
          onClick={togglePlayPause}
        >
          {isPlaying ? t('stop') : t('resume')} ({currentIndex + 1})
        </Button>
        <Button
          variant="contained"
          onClick={nextVideo}
          disabled={currentIndex === subjects.length - 1}
        >
          {t('nextSubject')} ({currentIndex + 2}/{subjects.length})
        </Button>    
      </Box>
      {hasNextPage && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'right', width: '100%', maxWidth: '640px' }}>
          <Button
            variant="contained"
            onClick={handleNextPage}
            color="secondary"
          >
            {t('nextPage')}
          </Button>
        </Box>
      )}      
    </Box>
  );
};

export default TVModePanel;