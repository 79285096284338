import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { topicsConfig } from '../config/topicsConfig';
import config from '../config';

const { dataNames, topicsSentiments } = config;

const TopicsGrid = ({topics}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const topicParam = params.get('id');

  const { t, i18n } = useTranslation();

  const getTopicUrl = id => {
    const lang = i18n.language;
    return `/topics?id=${id}&lang=${lang}`;
  };     

  const getIcon = (iconName) => {
    const IconComponent = MuiIcons[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  return (
    <>
        {topics?.length > 0 && (
            <Box sx={{ overflow: 'hidden', padding: '10px 0', margin: '20px 0' }}>
            <Typography className="section-header">{t('topics')}:</Typography>
            <Grid container spacing={2} alignItems="center">
            {topics.map((topic, index) => {
              const topicData = topicsConfig[topic[`${dataNames.topics}_index`]];
              return (
                topicsSentiments.includes(topicData.sentiment) && <Grid
                    item key={index}
                    sx={
                        topicParam === String(topic[`${dataNames.topics}_index`]) ?
                        {borderBottom: '2px solid red', alignItems: 'center'} :
                        {}
                    }
                >
                {getIcon(topicData.icon) && React.cloneElement(getIcon(topicData.icon), { sx: { color: topicData.color } })}
                <a href={getTopicUrl(topic[`${dataNames.topics}_index`])} className="subject-link">
                    {topic.topic.charAt(0).toUpperCase() + topic.topic.slice(1)} ({topic.count})
                </a>
                </Grid>
              );
            })}
            </Grid>
        </Box>  
        )}
    </>
  );
};

export default TopicsGrid;